import styled from "styled-components";
//  ;
export const SubmitBtn = styled.button`
  background-color: ${(props) => props.bgColor || "#be0303"};
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600px;
  letter-spacing: 1px;
  width: 100%;
  margin: 5px 0;
`;
export const Label = styled.label`
  width: 100%;
  color: #8a8a8a;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  padding: 7px;
`;
export const Form = styled.form`
  border-radius: 3px;
  box-sizing: border-box;
  font-size: 14px;
`;
export const Container = styled.div`
  padding: 10px 12px 30px 12px;
  margin: auto;
  max-width: 700px;
  box-shadow: 0 2px 50px rgba(0, 0, 0, 0.2);
  min-height: 100vh;
`;
export const FormInputControl = styled.div`
  margin: 10px 0;
  width: 100%;
`;
export const FormInputControlChild = styled.div`
  flex: 1;
  display: flex;
  padding: 2px;
  flex-wrap: wrap;
  align-items: center;
`;

export const FormInputContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;
export const PhoneNumber = styled.span`
  color: #8a8a8a;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 5px;
`;
export const RadioBtn = styled.input.attrs({ type: "radio" })`
  height: 15px;
  width: 15px;
`;

export const CheckBox = styled.input.attrs({ type: "checkbox" })`
  height: 15px;
  width: 15px;
`;
export const Input = styled.input`
  height: 40px;
  width: 100%;
  padding: 5px 10px;
  background-color: transparent;
  border: 1px solid ${(props) => props.bdColor || "#8a8a8a"};
`;
export const Select = styled.select`
  height: 40px;
  width: 100%;
  padding: 5px 10px;
  /* color: black; */
  background-color: transparent;
  border: 1px solid ${(props) => props.bdColor || "#8a8a8a"};
`;

export const TextArea = styled.textarea`
  width: 100%;
  padding: 5px 10px;
  background-color: transparent;
  border: 1px solid ${(props) => props.bdColor || "#8a8a8a"};
`;

const Paragragph = styled.p`
  color: red;
  font-size: 12px;
`;

export function FormValidationError({ text }) {
  return <Paragragph>{text}</Paragragph>;
}
export const FieldSet = styled.fieldset`
  padding: 10px 10px 10px 10px;
  border: 1px solid ${(props) => props.bdColor || "#be0303"};
  margin: 20px 0;
`;

export const FormInputContainerOptions = styled.div`
  width: 45%;
  font-size: 10px;
`;
export const TermsConditions = styled.p`
  text-align: center;
`;
