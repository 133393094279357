import React from "react";
import styled from "styled-components";
const Container = styled.div`
  width: 100%;
  box-shadow: 0 2px 50px rgba(0, 0, 0, 0.2);
  background-color: white;
  padding: 10px 10px;
  border-radius: 7px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "")};
  border: 1px solid rgb(221, 52, 50);
  border-top: ${(props) => props.borderTop};
`;

function Card({ children, borderTop, marginTop }) {
  return (
    <>
      <Container borderTop={borderTop} marginTop={marginTop}>
        {children}
      </Container>
      ;
    </>
  );
}

export default Card;
