import React, { createContext, useState } from "react";

export const contextContainer = createContext({});

function Context({ children }) {
  const [loading, setLoading] = useState(false);
  return (
    <contextContainer.Provider value={{ loading, setLoading }}>
      {children}
    </contextContainer.Provider>
  );
}

export default Context;
