import React, { useContext, useEffect, useState } from "react";
import { format } from "date-fns";
import malaria_header_img from "../../images/headerimges/malaria/malariabgImg.jpg";
import {
  Container,
  Form,
  FormInputContainer,
  FormInputControl,
  FormValidationError,
  Input,
  PhoneNumber,
  SubmitBtn,
  TermsConditions,
} from "../../components/components";
import "./radio.css";
//#00427C    #E3E9F5     #00196E
import RICIBs from "react-individual-character-input-boxes";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import Geocode from "react-geocode";
import { ngSates } from "../../utils";
import { Link, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { useMutation } from "react-query";
import { useMemo } from "react";
import styled from "styled-components";
import { contextContainer } from "../../Context";
import Hero from "../../components/header/Hero";

// #6794CE

const SurveyHeaderTitle = styled.h2`
  text-align: center;
  font-size: 15px;
  color: black;
`;
const SurveyInfo = styled.p`
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  padding: "10px";
  color: rgba(0, 0, 0, 0.6);
`;

const Card = styled.div`
  color: #00196e;
  width: 100%;
  background-color: #e3e9f5;
  padding: 10px 10px;
  margin-bottom: 20px;
  /* box-shadow: 0 2px 50px rgba(0, 0, 0, 0.2); */
  border: none;
  border-radius: 0px 0px 7px 7px;
`;
const CardHeader = styled.div`
  color: white;
  width: 100%;
  background-color: #00427c;
  padding: 5px 10px;
  border: none;
  border-radius: 7px 7px 0 0;
`;

const Label = styled.label`
  width: 100%;
  color: #00196e;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  padding: 7px;
`;

const customStyles = {
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    color: state.isSelected ? "#212529" : "#fff",
    backgroundColor: state.isSelected ? "#e3e9f5" : "#00427C",
  }),
  control: (defaultStyles, state) => ({
    ...defaultStyles,
    backgroundColor: "transparent",
    height: "40px",
    width: "100%",
    border: "1px solid #00427C",
  }),
};

function MalariaSurvey() {
  //state variabless
  Geocode.setApiKey(`${process.env.REACT_APP_GOOGLE_API}`);
  Geocode.setLanguage("en");
  // Geocode.setLocationType("ROOFTOP");
  Geocode.enableDebug();
  const { setLoading } = useContext(contextContainer);
  const [searchParams, setSearchParams] = useSearchParams();
  const [lgas, setLgas] = useState([]);
  const [lastPhone3Digits, setLastPhone3Digits] = useState("");
  const [state, setState] = useState("");
  const [lga, setLga] = useState("");
  const [town, setTown] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [isVissible, setIsvissible] = useState(true);
  const [params, setParams] = useState({
    campaignCode: "",
    otp: "",
    submittedByUserId: "",
    channel: "direct",
  });
  const [purchaseDate, setPurchaseDate] = useState("");
  const [prodName, setProdName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [completeAddressProvidedType, setCompleteAddressProvidedType] =
    useState("user_supplied");
  useMemo(
    () =>
      setParams({
        campaignCode: searchParams.get("reg"),
        otp: searchParams.get("otp"),
        submittedByUserId: parseInt(searchParams.get("userId")),
        channel: searchParams.get("channel") || "direct",
      }),
    []
  );
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_MALARIA_BASE_URL}/malaria/survey?surveyParticipantCode=${params.campaignCode}`
      )
      .then((resp) => {
        const data = resp.data.data;
        setPurchaseDate(
          format(new Date(data.surveySentDate), "yyyy-MM-dd hh:mm:ss")
        );
        setProdName(data.productName);
        setPhoneNumber(data.phone);
      })
      .catch((err) => {
        console.log(err);
        showAlert("Error", err.response.data.message, "error", "red");
      });
  }, []);

  useEffect(() => {
    if (params.channel === "direct") {
      navigator.permissions.query({ name: "geolocation" }).then((resp) => {
        if (resp.state === "granted") {
          console.log("granted");
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(success, error);
          }
        } else if (resp.state === "denied") console.log("Access Denied");
      });
    }
    function success(position) {
      setLongitude(position.coords.longitude);
      setLatitude(position.coords.latitude);
      console.log([position.coords.latitude, position.coords.longitude]);
      Geocode.fromLatLng(
        position.coords.latitude,
        position.coords.longitude
      ).then(
        (response) => {
          console.log(response);
          for (
            let i = 0;
            i < response.results[0].address_components.length;
            i++
          ) {
            for (
              let j = 0;
              j < response.results[0].address_components[i].types.length;
              j++
            ) {
              switch (response.results[0].address_components[i].types[j]) {
                case "neighborhood":
                  setTown(response.results[0].address_components[i].long_name);
                  break;
                case "administrative_area_level_2":
                  setLga(response.results[0].address_components[i].long_name);
                  break;
                case "administrative_area_level_1":
                  setState(response.results[0].address_components[i].long_name);
                  break;
                default:
              }
            }
          }
          setIsvissible(false);
        },
        (error) => {
          console.error(error);
        }
      );
    }

    function error(err) {
      console.log(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Alert function
  const showAlert = (title, text, icon, confirmButtonColor) => {
    Swal.fire({
      title: title,
      html: text,
      icon: icon,
      confirmButtonText: "OK",
      confirmButtonColor: confirmButtonColor,
    }).then((response) => {});
  };
  function handSetLatLng(e) {
    console.log(e.value);
    Geocode.fromAddress(`${e.value}, ${state} state Nigeria`).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setLatitude(lat);
        setLongitude(lng);
        console.log(lat, lng);
        setCompleteAddressProvidedType("system_generated");
      },
      (error) => {
        console.error(error);
      }
    );
  }

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ count: "count", date: "date" });
  function loadLga(state) {
    const selectedState = ngSates.filter((selectedState) => {
      if (state === selectedState.state.name) return selectedState.state;
    });

    //
    const lgaUpdate = [
      ...selectedState[0].state.locals,
      ...[{ name: "Not Stated", id: 200 }],
    ];
    console.log(lgaUpdate);
    setLgas(lgaUpdate);
  }
  const onsubmit = (data) => {
    console.log(data);
    const responses = [
      data?.malariaDetectionMethod,
      data?.price.value,
      data?.state?.value || state,
      data?.town || town,
      data?.boughtDrugsFor,
      data?.use_mosquito_net,
      data?.gender,
      data?.age.value,
      data?.treatmentLocation.value,
      data?.lga?.value || lga,
    ];

    const payload = {
      campaignCode: params.campaignCode,
      consent: "1",
      latitude: JSON.stringify(latitude),
      longitude: JSON.stringify(longitude),
      lastPhone3Digits,
      otp: params.otp,
      channel: params.channel,
      submittedByUserId: params.submittedByUserId,
      completeAddressProvidedType,
      responses: responses,
    };

    console.log(payload);
    mutate(payload);
  };

  function handlesetLastPhone3Digits(e) {
    setLastPhone3Digits(e);
  }

  async function getSubmitSurvey(data) {
    const response = await axios.post(
      `${process.env.REACT_APP_MALARIA_BASE_URL}/malaria/survey`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  }

  const { isLoading, mutate } = useMutation(
    (variables) => getSubmitSurvey(variables),
    {
      onSuccess(data, variables, context) {
        console.log(data);
        showAlert("Success", data.data.message, "success", "ok");
      },
      onError(error) {
        console.log(error);
        if (error.code === "ERR_NETWORK") {
          showAlert("Error", error.message, "error", "red");
        } else {
          showAlert("Error", error.response.data.message, "error", "red");
        }
        console.log(error.response.data.message);
      },
    }
  );

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  return (
    <Container style={{ backgroundColor: "#F9F9F9" }}>
      <Hero bgImg={malaria_header_img} />
      <Card
        style={{
          borderTop: "5px solid #00427c",
          borderRadius: "7px 7px 0 0",
          marginTop: "140px",
        }}>
        <SurveyHeaderTitle style={{ color: "#00196E" }}>
          Sproxil Malaria Drug Questionnaire
        </SurveyHeaderTitle>
        <hr style={{ border: "1px solid #00196E" }} />
        <SurveyInfo style={{ color: "#00196E" }}>
          Please answer the questions truthfully to help us be better informed
          about malaria drugs.You will receive 1000 Naira in airtime upon
          completion of the survey
        </SurveyInfo>
      </Card>

      <Form onSubmit={handleSubmit(onsubmit)}>
        <CardHeader>Product Info & user Info</CardHeader>
        <Card>
          <FormInputContainer>
            <Label>
              <b style={{ color: "#00196E" }}>Product Name: </b>
            </Label>

            <FormInputControl>{prodName}</FormInputControl>
          </FormInputContainer>
          <FormInputContainer>
            <Label>
              <b style={{ color: "#00196E" }}>Date of purchase: </b>
            </Label>
            <FormInputControl>{purchaseDate}</FormInputControl>
          </FormInputContainer>
          <FormInputContainer>
            <PhoneNumber style={{ color: "#00196E" }}>
              {phoneNumber.substring(0, phoneNumber.length - 3) ||
                "XXXXXXXXXXX"}
            </PhoneNumber>
            <FormInputControl>
              <RICIBs
                amount={3}
                handleOutputString={(e) => {
                  handlesetLastPhone3Digits(e);
                }}
                inputProps={[
                  {
                    style: {
                      width: "25px",
                      height: "25px",
                      fontSize: "14px",
                      border: "2px solid #00196E",
                    },
                  },
                  {
                    style: {
                      width: "25px",
                      height: "25px",
                      fontSize: "14px",
                      border: "2px solid #00196E",
                    },
                  },
                  {
                    style: {
                      width: "25px",
                      height: "25px",
                      fontSize: "14px",
                      border: "2px solid #00196E",
                    },
                  },
                ]}
              />
            </FormInputControl>
          </FormInputContainer>
        </Card>
        <CardHeader>
          <FormInputControl>
            <Label>
              <b style={{ color: "white" }}>
                How do you know you have malaria?
              </b>
            </Label>
            {errors.malariaDetectionMethod?.type === "required" && (
              <FormValidationError text={"This Field is Required"} />
            )}
          </FormInputControl>
        </CardHeader>
        <Card>
          <FormInputContainer style={{ display: "block" }}>
            <label class="Malaria-radio-container">
              I determined myself
              <input
                type="radio"
                name="malariaDetectionMethod"
                {...register("malariaDetectionMethod", { required: true })}
                value={" I determined myself"}
              />
              <span class="Malaria-radio-checkmark"></span>
            </label>

            <label class="Malaria-radio-container">
              A healthcare professional told me
              <input
                type="radio"
                name="malariaDetectionMethod"
                {...register("malariaDetectionMethod", { required: true })}
                value={"A healthcare professional told me"}
              />
              <span class="Malaria-radio-checkmark"></span>
            </label>

            <label class="Malaria-radio-container">
              I got a lab test
              <input
                type="radio"
                name="malariaDetectionMethod"
                {...register("malariaDetectionMethod", { required: true })}
                value={"I got a lab test"}
              />
              <span class="Malaria-radio-checkmark"></span>
            </label>

            <label class="Malaria-radio-container">
              I took a rapid diagnostic test (RDT)
              <input
                type="radio"
                name="malariaDetectionMethod"
                {...register("malariaDetectionMethod", { required: true })}
                value={"I took a rapid diagnostic test (RDT)"}
              />
              <span class="Malaria-radio-checkmark"></span>
            </label>
          </FormInputContainer>
        </Card>

        <CardHeader>
          <FormInputControl>
            <Label>
              <b style={{ color: "white", fontSize: "12px" }}>
                What price did you pay for your malaria medicine?
              </b>
              {errors.price?.type === "required" && (
                <FormValidationError text={"This Field is Required"} />
              )}
            </Label>
          </FormInputControl>
        </CardHeader>
        <Card>
          <FormInputControl>
            <Controller
              name="price"
              control={control}
              rules={{ required: true }}
              defaultValue={""}
              render={({ field }) => (
                <Select
                  styles={customStyles}
                  options={[
                    {
                      value: "Between ₦ 0 (free) and ₦ 100",
                      label: '"Between ₦ 0 (free) and ₦ 100"',
                    },
                    {
                      value: "Between ₦ 101 and ₦ 250",
                      label: "Between ₦ 101 and ₦ 250",
                    },

                    {
                      value: "Between ₦ 251 and ₦ 500",
                      label: " Between ₦ 251 and ₦ 500",
                    },

                    {
                      value: "Between ₦ 501 and ₦ 750",
                      label: "Between ₦ 501 and ₦ 750",
                    },
                    {
                      value: "Between ₦ 751 and ₦ 1000",
                      label: "Between ₦ 751 and ₦ 1000",
                    },
                    {
                      value: "Between ₦ 1001and ₦ 1500",
                      label: "Between ₦ 1001and ₦ 1500",
                    },
                    {
                      value: "Between ₦ 1501 and ₦ 2000",
                      label: "Between ₦ 1501 and ₦ 2000",
                    },
                    {
                      value: "Above ₦ 2000",
                      label: "Above ₦ 2000",
                    },
                  ]}
                  {...field}
                />
              )}
            />
          </FormInputControl>
        </Card>

        {isVissible && (
          <>
            <CardHeader>
              <FormInputControl>
                <Label>
                  {" "}
                  <b style={{ color: "white", fontSize: "12px" }}>
                    Name of state where you bought the drug
                  </b>
                </Label>
                {errors.state?.type === "required" && (
                  <FormValidationError text={"This Field is Required"} />
                )}
              </FormInputControl>
            </CardHeader>
            <Card>
              <FormInputControl>
                <Controller
                  name="state"
                  control={control}
                  rules={{ required: true }}
                  defaultValue={""}
                  render={({ field }) => (
                    <Select
                      styles={customStyles}
                      options={ngSates.map((state) => {
                        return {
                          label: state.state.name,
                          value: state.state.name,
                        };
                      })}
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        setState(e.value);
                        loadLga(e.value);
                      }}
                    />
                  )}
                />
              </FormInputControl>
            </Card>
          </>
        )}
        {isVissible && (
          <>
            {" "}
            <CardHeader>
              <FormInputControl>
                <Label>
                  {" "}
                  <b style={{ color: "white", fontSize: "12px" }}>
                    Name of LGA where you bought the drug
                  </b>{" "}
                </Label>
                {errors.lga?.type === "required" && (
                  <FormValidationError text={"This Field is Required"} />
                )}
              </FormInputControl>
            </CardHeader>
            <Card>
              <FormInputControl>
                <Controller
                  name="lga"
                  control={control}
                  rules={{ required: true }}
                  defaultValue={""}
                  render={({ field }) => (
                    <Select
                      styles={customStyles}
                      placeholder="Select a local government area"
                      options={
                        lgas.length > 0
                          ? lgas.map((lga) => {
                              return { value: lga.name, label: lga.name };
                            })
                          : []
                      }
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        handSetLatLng(e);
                      }}
                    />
                  )}
                />
              </FormInputControl>
            </Card>
          </>
        )}

        {isVissible && (
          <>
            <CardHeader>
              <FormInputControl>
                <Label>
                  {" "}
                  <b style={{ color: "white", fontSize: "12px" }}>
                    Name of town where you bought the drug
                  </b>
                </Label>
                {errors.town?.type === "required" && (
                  <FormValidationError text={"This Field is Required"} />
                )}
              </FormInputControl>
            </CardHeader>
            <Card>
              <FormInputControl>
                <Input
                  style={{
                    border: "1px solid #00427c",
                    backgroundColor: "transparent",
                  }}
                  {...register("town", { required: true })}
                />
              </FormInputControl>
            </Card>
          </>
        )}
        <CardHeader>
          <FormInputControl>
            <Label>
              {" "}
              <b style={{ color: "white", fontSize: "12px" }}>
                Who did you buy the malaria medicine for?
              </b>
            </Label>
            {errors.boughtDrugsFor?.type === "required" && (
              <FormValidationError text={"This Field is Required"} />
            )}
          </FormInputControl>
        </CardHeader>
        <Card>
          <FormInputContainer style={{ display: "block" }}>
            <label class="Malaria-radio-container">
              Myself
              <input
                type="radio"
                name={"boughtDrugsFor"}
                {...register("boughtDrugsFor", { required: true })}
                value={"Myself"}
              />
              <span class="Malaria-radio-checkmark"></span>
            </label>

            <label class="Malaria-radio-container">
              My child 0 - 5 years old
              <input
                type="radio"
                name={"boughtDrugsFor"}
                {...register("boughtDrugsFor", { required: true })}
                value={"My child 0 - 5 years old"}
              />
              <span class="Malaria-radio-checkmark"></span>
            </label>

            <label class="Malaria-radio-container">
              Someone else
              <input
                type="radio"
                name={"boughtDrugsFor"}
                {...register("boughtDrugsFor", { required: true })}
                value={"My child over 5 years old"}
              />
              <span class="Malaria-radio-checkmark"></span>
            </label>

            <label class="Malaria-radio-container">
              My child over 5 years old
              <input
                type="radio"
                name={"boughtDrugsFor"}
                {...register("boughtDrugsFor", { required: true })}
                value={"Someone else"}
              />
              <span class="Malaria-radio-checkmark"></span>
            </label>
          </FormInputContainer>
        </Card>
        <CardHeader>
          <FormInputControl>
            <Label>
              {" "}
              <b style={{ color: "white", fontSize: "12px" }}>
                Do you use a mosquito net?
              </b>{" "}
            </Label>
            {errors.use_mosquito_net?.type === "required" && (
              <FormValidationError text={"This Field is Required"} />
            )}
          </FormInputControl>
        </CardHeader>
        <Card>
          <FormInputContainer style={{ display: "block" }}>
            <label class="Malaria-radio-container">
              Yes, it is treated with medicine
              <input
                type="radio"
                {...register("use_mosquito_net", { required: true })}
                name="use_mosquito_net"
                value="Yes, it is treated with medicine"
              />
              <span class="Malaria-radio-checkmark"></span>
            </label>

            <label class="Malaria-radio-container">
              Yes, but I don’t know if it is treated with medicine
              <input
                type="radio"
                {...register("use_mosquito_net", { required: true })}
                name="use_mosquito_net"
                value="Yes, but I don’t know if it is treated with medicine"
              />
              <span class="Malaria-radio-checkmark"></span>
            </label>

            <label class="Malaria-radio-container">
              No
              <input
                type="radio"
                {...register("use_mosquito_net", { required: true })}
                name="use_mosquito_net"
                value="No"
              />
              <span class="Malaria-radio-checkmark"></span>
            </label>
          </FormInputContainer>
        </Card>
        <CardHeader>
          <FormInputControl>
            <Label>
              {" "}
              <b style={{ color: "white", fontSize: "12px" }}>
                What is your gender?
              </b>{" "}
            </Label>
            {errors.gender?.type === "required" && (
              <FormValidationError text={"This Field is Required"} />
            )}
          </FormInputControl>
        </CardHeader>
        <Card>
          <FormInputContainer style={{ display: "block" }}>
            <label class="Malaria-radio-container">
              Male
              <input
                type="radio"
                {...register("gender", { required: true })}
                name="gender"
                value="Male"
              />
              <span class="Malaria-radio-checkmark"></span>
            </label>

            <label class="Malaria-radio-container">
              Female and pregnant
              <input
                type="radio"
                {...register("gender", { required: true })}
                name="gender"
                value="Female and pregnant"
              />
              <span class="Malaria-radio-checkmark"></span>
            </label>

            <label class="Malaria-radio-container">
              Female_and_not_pregnant
              <input
                type="radio"
                {...register("gender", { required: true })}
                name="gender"
                value="Female_and_not_pregnant"
              />
              <span class="Malaria-radio-checkmark"></span>
            </label>

            <label class="Malaria-radio-container">
              None of the above
              <input
                type="radio"
                {...register("gender", { required: true })}
                name="gender"
                value="None of the above"
              />
              <span class="Malaria-radio-checkmark"></span>
            </label>
          </FormInputContainer>
        </Card>
        <CardHeader>
          <FormInputControl>
            <Label>
              {" "}
              <b style={{ color: "white", fontSize: "12px" }}>
                What is your age?
              </b>{" "}
            </Label>
            {errors.age?.type === "required" && (
              <FormValidationError text={"This Field is Required"} />
            )}
          </FormInputControl>
        </CardHeader>
        <Card>
          <FormInputControl>
            <Controller
              name="age"
              control={control}
              rules={{ required: true }}
              defaultValue={""}
              render={({ field }) => (
                <Select
                  styles={customStyles}
                  placeholder="Please Select Age Range"
                  options={[
                    {
                      value: "Under 18 years old",
                      label: "Under 18 years old",
                    },
                    { value: "18-24 years old", label: "18-24 years old" },
                    { value: "25-34 years old", label: "25-34 years old" },
                    { value: "35-44 years old", label: "35-44 years old" },
                    { value: "45-54 years old", label: "45-54 years old" },
                    { value: "55-64 years old", label: "55-64 years old" },
                    { value: "65-74 years old", label: "65-74 years old" },
                    { value: "75 years or older", label: "75 years or older" },
                  ]}
                  {...field}
                />
              )}
            />
          </FormInputControl>
        </Card>
        <CardHeader>
          <FormInputControl>
            <Label>
              {" "}
              <b style={{ color: "white", fontSize: "12px" }}>
                When you’re sick, where do you usually get treatment?
              </b>{" "}
            </Label>
            {errors.treatmentLocation?.type === "required" && (
              <FormValidationError text={"This Field is Required"} />
            )}
          </FormInputControl>
        </CardHeader>
        <Card>
          <FormInputControl>
            <Controller
              name="treatmentLocation"
              control={control}
              rules={{ required: true }}
              defaultValue={""}
              render={({ field }) => (
                <Select
                  styles={customStyles}
                  placeholder="Please Select A Treatment Place"
                  options={[
                    { value: "Drug store", label: "Drug store" },
                    { value: "Pharmacy", label: "Pharmacy" },
                    {
                      value: "Private clinic/hospital",
                      label: "Private clinic/hospital",
                    },
                    {
                      value: "Government clinic/hospital",
                      label: "Government clinic/hospital",
                    },
                    {
                      value: "Traditional healer",
                      label: "Traditional healer",
                    },
                    { value: "55-64 years old", label: "55-64 years old" },
                    { value: "None of the above", label: "None of the above" },
                  ]}
                  {...field}
                />
              )}
            />
          </FormInputControl>
        </Card>
        <FormInputControl style={{ width: "100px" }}>
          <SubmitBtn bgColor={"#00427C"}>Submit</SubmitBtn>
        </FormInputControl>
        <TermsConditions>
          <Link to="https://sproxil.com/terms-of-service/" target="_blank">
            Terms and Conditions
          </Link>
        </TermsConditions>
      </Form>
    </Container>
  );
}

export default MalariaSurvey;
