import React from "react";
import styled from "styled-components";
const Wrapper = styled.div`
  width: 100%;
  height: 194px;
  position: fixed;
  top: 0px;
  right: 0;
  left: 0;
  z-index: 9999999999999;
  @media screen and (max-width: 481px) {
    height: 120px;
  }
  @media screen and (min-width: 481px) {
    display: none;
  }
  background: ${(props) => `linear-gradient(
      to bottom,
       rgba(128, 128, 128, 0.1),
        rgba(128, 128, 128, 0.4)
    ),url(${props.bgImg}) `};
  background-size: cover;
  background-repeat: no-repeat;
`;
const Img = styled.img`
  width: 90px;
  object-fit: cover;
`;

const BrandImgWrapper = styled.div`
  display: flex;
  justify-content: end;
  padding: 10px 5px;
  align-items: center;
`;
// style={{ borderRadius: "50%" }}
function Hero({ bgImg, brandLogo }) {
  return (
    <Wrapper bgImg={bgImg}>
      <BrandImgWrapper>
        {/* <Img src="https://sproxil.com/wp-content/uploads/elementor/thumbs/logo-p4gecdm10t4rteqhobr54u1ih55qbnoisrzqg699ts.png" /> */}
        <Img src={brandLogo} />
      </BrandImgWrapper>
    </Wrapper>
  );
}

export default Hero;
