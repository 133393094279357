import React from "react";
import styled from "styled-components";
const HeaderTitle = styled.h2`
  text-align: center;
  font-size: 15px;
  color: black;
`;
const HeaderText = styled.p`
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  padding: "10px";
  color: rgba(0, 0, 0, 0.6);
`;
const Container = styled.div`
  width: 100%;
`;
function Header({ headerTilte, headerText }) {
  return (
    <Container>
      <HeaderTitle>{headerTilte}</HeaderTitle>
      <hr />
      <HeaderText>{headerText}</HeaderText>
      <hr />
    </Container>
  );
}

export default Header;
