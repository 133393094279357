import React from "react";
import styled from "styled-components";
import loadingImage from "../images/loadImage1.gif";
const Container = styled.div`
  height: 60px;
  border-radius: 5px;
  color: #f5f5f5;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  /* or 110% */
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  color: #be0303;
`;
function Loading() {
  return (
    <Container>
      <img src={loadingImage} alt="" />
    </Container>
  );
}

export default Loading;
