import "./App.css";
import { Route, Routes } from "react-router-dom";
import MalariaSurvey from "./surveys/malaria-survey/MalariaSurvey";
import ModalDrop from "./components/modalDrop";
import { useContext } from "react";
import { contextContainer } from "./Context";
import Loading from "./components/Loading";
import TropicalNatural from "./surveys/tropical-natural/TropicalNatural";
import MomsPride from "./surveys/moms-pride/MomsPride";
import RainOil from "./surveys/rainoil/RainOil";

function App() {
  const { loading } = useContext(contextContainer);
  return (
    <>
      {loading && (
        <ModalDrop>
          <Loading />
        </ModalDrop>
      )}

      <Routes>
        <Route path="/" element={<MalariaSurvey />} />
        <Route path="/tropical" element={<TropicalNatural />} />
        <Route path="/moms-pride" element={<MomsPride />} />
        <Route path="/rainoil" element={<RainOil />} />
      </Routes>
    </>
  );
}

export default App;
