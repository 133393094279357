import React, { useContext, useEffect, useState } from "react";
import { format } from "date-fns";
import tropical_header_img from "../../images/headerimges/dudu-osun/bgImg.jpeg";
import brandLogo from "../../images/headerimges/dudu-osun/logo.jpeg";
import "./checkbox.css";
import "./radio.css";
import {
  Container,
  Form,
  FormInputContainer,
  FormInputControl,
  FormValidationError,
  Input,
  Label,
  PhoneNumber,
  SubmitBtn,
  FieldSet,
  FormInputContainerOptions,
  TermsConditions,
} from "../../components/components";
//
import RICIBs from "react-individual-character-input-boxes";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import Geocode from "react-geocode";
import { ngSates } from "../../utils";
import { Link, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { useMutation } from "react-query";
import { useMemo } from "react";
import { contextContainer } from "../../Context";
import img1 from "../../images/prods/prod1.png";
import img2 from "../../images/prods/prod2.png";
import img3 from "../../images/prods/prod3.png";
import img4 from "../../images/prods/prod4.png";
import img5 from "../../images/prods/prod5.png";
import img6 from "../../images/prods/prod6.png";
import Hero from "../../components/header/Hero";
import Header from "../../components/header/Header";
import Card from "../../components/card/Card";

function TropicalNatural() {
  //state variabless
  Geocode.setApiKey(`${process.env.REACT_APP_GOOGLE_API}`);
  Geocode.setLanguage("en");
  Geocode.setLocationType("ROOFTOP");
  Geocode.enableDebug();
  const { setLoading } = useContext(contextContainer);
  const [searchParams, setSearchParams] = useSearchParams();
  const [lgas, setLgas] = useState([]);
  const [lastPhone3Digits, setLastPhone3Digits] = useState("");
  const [state, setState] = useState("");
  const [lga, setLga] = useState("");
  const [town, setTown] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [isVissible, setIsvissible] = useState(true);
  const [params, setParams] = useState({
    campaignCode: "",
    otp: "",
    submittedByUserId: "",
    channel: "direct",
  });
  const [purchaseDate, setPurchaseDate] = useState("");
  const [prodName, setProdName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [completeAddressProvidedType, setCompleteAddressProvidedType] =
    useState("user_supplied");

  useMemo(
    () =>
      setParams({
        campaignCode: searchParams.get("code"),
        otp: searchParams.get("otp"),
        submittedByUserId: parseInt(searchParams.get("userId")),
        channel: searchParams.get("channel") || "direct",
      }),
    []
  );

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_DUDU_OSUN_BASE_URL}/survey?surveyParticipantCode=${params.campaignCode}`
      )
      .then((resp) => {
        const data = resp.data.data;
        setPurchaseDate(
          format(new Date(data.surveySentDate), "yyyy-MM-dd hh:mm:ss")
        );
        setProdName(data.productName);
        setPhoneNumber(data.phone);
      })
      .catch((err) => {
        console.log(err);
        if (err.message === "Network Error")
          showAlert("Error", err.message, "error", "red");
        else showAlert("Error", err.response.data.message, "error", "red");
      });
  }, []);

  useEffect(() => {
    if (params.channel === "direct") {
      navigator.permissions.query({ name: "geolocation" }).then((resp) => {
        if (resp.state === "granted") {
          console.log("granted");
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(success, error);
          }
        } else if (resp.state === "denied") console.log("Access Denied");
      });
    }
    function success(position) {
      setLongitude(position.coords.longitude);
      setLatitude(position.coords.latitude);
      Geocode.fromLatLng(
        position.coords.latitude,
        position.coords.longitude
      ).then(
        (response) => {
          for (
            let i = 0;
            i < response.results[0].address_components.length;
            i++
          ) {
            for (
              let j = 0;
              j < response.results[0].address_components[i].types.length;
              j++
            ) {
              switch (response.results[0].address_components[i].types[j]) {
                case "neighborhood":
                  setTown(response.results[0].address_components[i].long_name);
                  break;
                case "administrative_area_level_2":
                  setLga(response.results[0].address_components[i].long_name);
                  break;
                case "administrative_area_level_1":
                  setState(response.results[0].address_components[i].long_name);
                  break;
                default:
              }
            }
          }
          setIsvissible(false);
          setCompleteAddressProvidedType("system_generated");
        },
        (error) => {
          console.error(error);
        }
      );
    }

    function error(err) {
      console.log(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Alert function
  const showAlert = (title, text, icon, confirmButtonColor) => {
    Swal.fire({
      title: title,
      html: text,
      icon: icon,
      confirmButtonText: "OK",
      confirmButtonColor: confirmButtonColor,
    }).then((response) => {});
  };
  function handSetLatLng(e) {
    console.log(e.value);
    Geocode.fromAddress(`${e.value}, ${state} state Nigeria`).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setLatitude(lat);
        setLongitude(lng);
        console.log(lat, lng);
        setCompleteAddressProvidedType("system_generated");
      },
      (error) => {
        console.error(error);
      }
    );
  }

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState,
    formState: { errors },
  } = useForm({ count: "count", date: "date" });
  function loadLga(state) {
    const selectedState = ngSates.filter((selectedState) => {
      return state === selectedState.state.name && selectedState.state;
    });
    setLgas(selectedState[0].state.locals);
  }
  const onsubmit = (data) => {
    console.log(data);
    let reasonOptions = [
      "The price is reasonable",
      "I like how it smells",
      "To clear pimples",
      "It last a long time before it gets finished",
      "I couldn't find my preferred soap in the store",
    ];
    let otherProdOptions = [
      "Skin cream or lotion",
      "Hair shampoo",
      "Body spray or perfume",
      "Medicated soap",
      "Nothing else",
    ];

    let prodAwarenessOption = [
      "Dudu-Osun Fresh Fragnance",
      "Dudu-Osun Classic Fragnance",
      "Dudu-Osun Fragnance Free",
      "Dudu-Osun Shea Buter Fragnance Free",
      " Dudu-Osun Shea Buter With Fragnance",
      "Dudu-Osun Moisturizing Lotion",
    ];

    let prod = [...data?.otherProd, ...otherProdOptions];
    let reasons = [...data?.reason, ...reasonOptions];
    let awareness = [...data?.prodAwareness, ...prodAwarenessOption];
    const reasonsArray = reasonOptions.map((item, index) => {
      if (reasons.indexOf(item) !== reasons.lastIndexOf(item)) {
        return item;
      } else return null;
    });
    const prodArray = otherProdOptions.map((item, index) => {
      if (prod.indexOf(item) !== prod.lastIndexOf(item)) {
        return item;
      } else return null;
    });
    const awarenessArray = prodAwarenessOption.map((item, index) => {
      if (awareness.indexOf(item) !== awareness.lastIndexOf(item)) {
        return item;
      } else return null;
    });
    console.log(reasonsArray);
    const responses = [
      data?.malariaDetectionMethod,
      ...reasonsArray,
      data?.price.value,
      data?.treatmentLocation.value,
      data?.boughtDrugsFor,
      data?.age.value,
      data?.gender,
      ...prodArray,
      ...awarenessArray,
      data?.state?.value || state,
      data?.town || town,
      data?.lga?.value || lga,
    ];

    const payload = {
      campaignCode: params.campaignCode,
      consent: "1",
      latitude: JSON.stringify(latitude),
      longitude: JSON.stringify(longitude),
      lastPhone3Digits,
      otp: params.otp,
      channel: params.channel,
      submittedByUserId: params.submittedByUserId,
      completeAddressProvidedType,
      responses: responses,
    };

    console.log(payload);
    mutate(payload);
  };

  function handlesetLastPhone3Digits(e) {
    setLastPhone3Digits(e);
  }

  async function getSubmitSurvey(data) {
    const response = await axios.post(
      `${process.env.REACT_APP_DUDU_OSUN_BASE_URL}/survey`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  }

  const { isLoading, mutate } = useMutation(
    (variables) => getSubmitSurvey(variables),
    {
      onSuccess(data, variables, context) {
        showAlert("Success", data.data.message, "success", "green");
      },
      onError(error) {
        console.log(error);
        if (error.code === "ERR_NETWORK") {
          showAlert("Error", error.message, "error", "red");
        } else {
          showAlert("Error", error.response.data.message, "error", "red");
        }
        console.log(error.response.data.message);
      },
    }
  );

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  return (
    <Container>
      <Hero bgImg={tropical_header_img} brandLogo={brandLogo} />
      <Card borderTop={"5px solid rgb(68,147,72)"} marginTop={"120px"}>
        <Header
          headerText={`Please answer the questions truthfully to help us be better informed
        about Tropical Naturals.You will receive 500 Naira in airtime upon
        completion of the survey`}
          headerTilte={" Sproxil Dudu Osun soap Questionnaire"}
        />
      </Card>
      <Form onSubmit={handleSubmit(onsubmit)}>
        <Card>
          <FormInputContainer>
            <Label>
              <b style={{ color: "black" }}>Product Name: </b>
            </Label>

            <FormInputControl>{prodName}</FormInputControl>
          </FormInputContainer>
          <FormInputContainer>
            <FormInputControl>
              <Label>
                <b style={{ color: "black" }}>Date of purchase: </b>
              </Label>
            </FormInputControl>
            <FormInputControl>{purchaseDate}</FormInputControl>
          </FormInputContainer>
          <FormInputControl>
            <Label>
              {" "}
              <b style={{ color: "black" }}>Please complete your phone no</b>
            </Label>
            <FormInputContainer style={{ marginTop: "0" }}>
              <PhoneNumber>
                {phoneNumber.substring(0, phoneNumber.length - 3) ||
                  "XXXXXXXXXXX"}
              </PhoneNumber>
              <FormInputControl>
                <RICIBs
                  amount={3}
                  handleOutputString={(e) => {
                    handlesetLastPhone3Digits(e);
                  }}
                  inputProps={[
                    {
                      style: {
                        width: "25px",
                        height: "25px",
                        fontSize: "16px",
                        border: "2px solid rgb(68,147,72)",
                      },
                    },
                    {
                      style: {
                        width: "25px",
                        height: "25px",
                        fontSize: "14px",
                        border: "2px solid rgb(68,147,72)",
                      },
                    },
                    {
                      style: {
                        width: "25px",
                        height: "25px",
                        fontSize: "14px",
                        border: "2px solid rgb(68,147,72)",
                      },
                    },
                  ]}
                />
              </FormInputControl>
            </FormInputContainer>
          </FormInputControl>
        </Card>

        <Card>
          <FormInputControl>
            <Label>
              <b style={{ color: "black" }}>
                Is this your first time using Dudu Osun soap?
              </b>
            </Label>
            {errors.malariaDetectionMethod?.type === "required" && (
              <FormValidationError text={"This Field is Required"} />
            )}
          </FormInputControl>

          <FieldSet>
            <FormInputContainer style={{ display: "block" }}>
              <label className="radio-container">
                YES
                <input
                  type="radio"
                  name="malariaDetectionMethod"
                  {...register("malariaDetectionMethod", { required: true })}
                  value={"Yes"}
                />
                <span className="radio-checkmark"></span>
              </label>

              <label className="radio-container">
                No, I have used it for the last 1 year
                <input
                  type="radio"
                  {...register("malariaDetectionMethod", { required: true })}
                  name="malariaDetectionMethod"
                  value={"No, I have used it for the last 1 year"}
                />
                <span className="radio-checkmark"></span>
              </label>
              <label className="radio-container">
                No, I have used it for the last 2-4 years
                <input
                  type="radio"
                  {...register("malariaDetectionMethod", { required: true })}
                  name="malariaDetectionMethod"
                  value={"No, I have used it for the last 2-4 years"}
                />
                <span className="radio-checkmark"></span>
              </label>

              <label className="radio-container">
                No, I have used it for more than 5 years
                <input
                  type="radio"
                  {...register("malariaDetectionMethod", { required: true })}
                  name="malariaDetectionMethod"
                  value={"No, I have used it for more than 5 years"}
                />
                <span className="radio-checkmark"></span>
              </label>
            </FormInputContainer>
          </FieldSet>
        </Card>

        <Card>
          <FormInputControl>
            <Label>
              <b style={{ color: "black" }}>
                Why did you buy Dudu Osun soap? (Select all that apply)
              </b>
            </Label>
            {errors.reason?.type === "required" && (
              <FormValidationError text={"This Field is Required"} />
            )}
          </FormInputControl>

          <FieldSet>
            <FormInputContainer style={{ display: "block" }}>
              <label className="checkbox-container">
                The price is reasonable
                <input
                  type="checkbox"
                  name="reason"
                  {...register("reason", { required: true })}
                  value={"The price is reasonable"}
                />
                <span className="checkbox-checkmark"></span>
              </label>
              <label className="checkbox-container">
                I like how it smells
                <input
                  type="checkbox"
                  name="reason"
                  {...register("reason", { required: true })}
                  value={"I like how it smells"}
                />
                <span className="checkbox-checkmark"></span>
              </label>
              <label className="checkbox-container">
                To clear pimples
                <input
                  type="checkbox"
                  name="reason"
                  {...register("reason", { required: true })}
                  value={"To clear pimples"}
                />
                <span className="checkbox-checkmark"></span>
              </label>
              <label className="checkbox-container">
                It last a long time before it gets finished
                <input
                  type="checkbox"
                  name="reason"
                  {...register("reason", { required: true })}
                  value={"It last a long time before it gets finished"}
                />
                <span className="checkbox-checkmark"></span>
              </label>
              <label className="checkbox-container">
                I couldn't find my preferred soap in the store
                <input
                  type="checkbox"
                  name="reason"
                  {...register("reason", { required: true })}
                  value={"I couldn't find my preferred soap in the store"}
                />
                <span className="checkbox-checkmark"></span>
              </label>
            </FormInputContainer>
          </FieldSet>
        </Card>
        <Card>
          <FormInputControl>
            <Label>
              <b style={{ color: "black", fontSize: "12px" }}>
                What price did you pay for your Dudu Osun?
              </b>
            </Label>
            {errors.price?.type === "required" && (
              <FormValidationError text={"This Field is Required"} />
            )}
          </FormInputControl>
          <FormInputControl>
            <Controller
              name="price"
              control={control}
              rules={{ required: true }}
              defaultValue={""}
              render={({ field }) => (
                <Select
                  options={[
                    {
                      value: "Under ₦ 300 ",
                      label: "Under ₦ 300 ",
                    },
                    {
                      value: "Between ₦ 300 and ₦ 500",
                      label: "Between ₦ 300 and ₦ 500",
                    },
                    {
                      value: "Between ₦ 501 and ₦ 1000",
                      label: "Between ₦ 501 and ₦ 1000",
                    },
                    {
                      value: "Between ₦ 1001 and ₦ 2000",
                      label: "Between ₦ 1001 and ₦ 2000",
                    },
                    {
                      value: "Between ₦ 2001 and ₦ 4000",
                      label: "Between ₦ 2001 and ₦ 4000",
                    },
                    {
                      value: "Between ₦ 4001 and ₦ 6000",
                      label: "Between ₦ 4001 and ₦ 6000",
                    },
                    {
                      value: "Between ₦ 6001 and ₦ 8000",
                      label: "Between ₦ 6001 and ₦ 8000",
                    },
                    {
                      value: "Between ₦ 8,001 and ₦ 10,000",
                      label: "Between ₦ 8,001 and ₦ 10,000",
                    },
                    {
                      value: "Above ₦ 10,000",
                      label: "Above ₦ 10,000",
                    },
                  ]}
                  {...field}
                />
              )}
            />
          </FormInputControl>
        </Card>
        <Card>
          <FormInputControl>
            <Label>
              <b style={{ color: "black" }}>
                What other product do you wish the Dudu Osun manufacturer made?
                (Select all that apply)
              </b>
            </Label>
            {errors.malariaDetectionMethod?.type === "required" && (
              <FormValidationError text={"This Field is Required"} />
            )}
          </FormInputControl>
          <FieldSet>
            <FormInputContainer style={{ display: "block" }}>
              <label className="checkbox-container">
                Skin cream or lotion
                <input
                  type="checkbox"
                  name="otherProd"
                  {...register("otherProd", { require: true })}
                  value={"Skin cream or lotion"}
                />
                <span className="checkbox-checkmark"></span>
              </label>

              <label className="checkbox-container">
                Hair shampoo
                <input
                  type="checkbox"
                  name="otherProd"
                  {...register("otherProd", { require: true })}
                  value={"Hair shampoo"}
                />
                <span className="checkbox-checkmark"></span>
              </label>

              <label className="checkbox-container">
                Body spray or perfume
                <input
                  type="checkbox"
                  name="otherProd"
                  {...register("otherProd", { require: true })}
                  value={"Body spray or perfume"}
                />
                <span className="checkbox-checkmark"></span>
              </label>

              <label className="checkbox-container">
                Medicated soap
                <input
                  type="checkbox"
                  name="otherProd"
                  {...register("otherProd", { require: true })}
                  value={"Medicated soap"}
                />
                <span className="checkbox-checkmark"></span>
              </label>

              <label className="checkbox-container">
                Nothing else
                <input
                  type="checkbox"
                  name="otherProd"
                  {...register("otherProd", { require: true })}
                  value={"Nothing else"}
                />
                <span className="checkbox-checkmark"></span>
              </label>
            </FormInputContainer>
          </FieldSet>
        </Card>

        {isVissible && (
          <Card>
            <>
              <FormInputControl>
                <Label>
                  {" "}
                  <b style={{ color: "black", fontSize: "12px" }}>
                    Name of state where you bought Dudu Osun
                  </b>
                </Label>
                {errors.state?.type === "required" && (
                  <FormValidationError text={"This Field is Required"} />
                )}
              </FormInputControl>
              <FormInputControl>
                <Controller
                  name="state"
                  control={control}
                  rules={{ required: true }}
                  defaultValue={""}
                  render={({ field }) => (
                    <Select
                      options={ngSates.map((state) => {
                        return {
                          label: state.state.name,
                          value: state.state.name,
                        };
                      })}
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        setState(e.value);
                        loadLga(e.value);
                      }}
                    />
                  )}
                />
              </FormInputControl>
            </>
          </Card>
        )}
        {isVissible && (
          <Card>
            <>
              {" "}
              <FormInputControl>
                <Label>
                  {" "}
                  <b style={{ color: "black", fontSize: "12px" }}>
                    Name of LGA where you bought Dudu Osun
                  </b>{" "}
                </Label>
                {errors.lga?.type === "required" && (
                  <FormValidationError text={"This Field is Required"} />
                )}
              </FormInputControl>
              <FormInputControl>
                <Controller
                  name="lga"
                  control={control}
                  rules={{ required: true }}
                  defaultValue={""}
                  render={({ field }) => (
                    <Select
                      placeholder="Select a local government area"
                      options={
                        lgas.length > 0
                          ? lgas.map((lga) => {
                              return { value: lga.name, label: lga.name };
                            })
                          : []
                      }
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        handSetLatLng(e);
                      }}
                    />
                  )}
                />
              </FormInputControl>
            </>
          </Card>
        )}

        {isVissible && (
          <Card>
            <>
              <FormInputControl>
                <Label>
                  {" "}
                  <b style={{ color: "black", fontSize: "12px" }}>
                    Name of town where you bought Dudu Osun
                  </b>
                </Label>
                {errors.town?.type === "required" && (
                  <FormValidationError text={"This Field is Required"} />
                )}
              </FormInputControl>
              <FormInputControl>
                <Input {...register("town", { required: true })} />
              </FormInputControl>
            </>
          </Card>
        )}

        <Card>
          <FormInputControl>
            <Label>
              {" "}
              <b style={{ color: "black", fontSize: "12px" }}>
                Who did you buy the Dudu Osun soap for?
              </b>
            </Label>
            {errors.boughtDrugsFor?.type === "required" && (
              <FormValidationError text={"This Field is Required"} />
            )}
          </FormInputControl>
          <FieldSet>
            <FormInputContainer style={{ display: "block" }}>
              <label className="radio-container">
                Myself
                <input
                  type="radio"
                  name={"boughtDrugsFor"}
                  {...register("boughtDrugsFor", { required: true })}
                  value={"Myself"}
                />
                <span className="radio-checkmark"></span>
              </label>

              <label className="radio-container">
                My husband/boyfriend
                <input
                  type="radio"
                  name={"boughtDrugsFor"}
                  {...register("boughtDrugsFor", { required: true })}
                  value={"My husband/boyfriend"}
                />
                <span className="radio-checkmark"></span>
              </label>

              <label className="radio-container">
                My wife/girlfriend
                <input
                  type="radio"
                  name={"boughtDrugsFor"}
                  {...register("boughtDrugsFor", { required: true })}
                  value={"My wife/girlfriend"}
                />
                <span className="radio-checkmark"></span>
              </label>

              <label className="radio-container">
                My child
                <input
                  type="radio"
                  name={"boughtDrugsFor"}
                  {...register("boughtDrugsFor", { required: true })}
                  value={"My child"}
                />
                <span className="radio-checkmark"></span>
              </label>

              <label className="radio-container">
                Someone else
                <input
                  type="radio"
                  name={"boughtDrugsFor"}
                  {...register("boughtDrugsFor", { required: true })}
                  value={"Someone else"}
                />
                <span className="radio-checkmark"></span>
              </label>
            </FormInputContainer>
          </FieldSet>
        </Card>
        <Card>
          <FormInputControl>
            <Label>
              {" "}
              <b style={{ color: "black", fontSize: "12px" }}>
                Which of the following Dudu Osun products do you know about?
                (Select all that apply)
              </b>{" "}
            </Label>
            {errors.prodAwareness?.type === "required" && (
              <FormValidationError text={"This Field is Required"} />
            )}
          </FormInputControl>

          <FormInputContainer style={{ flexWrap: "wrap" }}>
            <FormInputContainerOptions>
              <img width={150} height={150} src={img1} alt="" srcset="" />
              <div>
                <label className="checkbox-container">
                  Dudu-Osun Classic Fragnance
                  <input
                    type="checkbox"
                    name="prodAwareness"
                    {...register("prodAwareness", { required: true })}
                    value={"Dudu-Osun Classic Fragnance"}
                  />
                  <span className="checkbox-checkmark"></span>
                </label>
              </div>
            </FormInputContainerOptions>
            <FormInputContainerOptions>
              <img width={150} height={150} src={img2} alt="" srcset="" />
              <div>
                <label className="checkbox-container">
                  Dudu-Osun Fresh Fragnance
                  <input
                    type="checkbox"
                    name="prodAwareness"
                    {...register("prodAwareness", { required: true })}
                    value={"Dudu-Osun Fresh Fragnance"}
                  />
                  <span className="checkbox-checkmark"></span>
                </label>
              </div>
            </FormInputContainerOptions>
            <FormInputContainerOptions>
              <img width={150} height={150} src={img3} alt="" srcset="" />
              <div>
                <label className="checkbox-container">
                  Dudu-Osun Shea Buter Fragnance Free
                  <input
                    type="checkbox"
                    name="prodAwareness"
                    {...register("prodAwareness", { required: true })}
                    va
                    value={"Dudu-Osun Shea Buter Fragnance Free"}
                  />
                  <span className="checkbox-checkmark"></span>
                </label>
              </div>
            </FormInputContainerOptions>
            <FormInputContainerOptions>
              <img width={150} height={150} src={img4} alt="" srcset="" />
              <div>
                <label className="checkbox-container">
                  "Dudu-Osun Fragnance Free
                  <input
                    type="checkbox"
                    name="prodAwareness"
                    {...register("prodAwareness", { required: true })}
                    value={"Dudu-Osun Fragnance Free"}
                  />
                  <span className="checkbox-checkmark"></span>
                </label>
              </div>
            </FormInputContainerOptions>

            <FormInputContainerOptions>
              <img width={150} height={150} src={img5} alt="" srcset="" />
              <div>
                <label className="checkbox-container">
                  Dudu-Osun Shea Buter With Fragnance
                  <input
                    type="checkbox"
                    name="prodAwareness"
                    {...register("prodAwareness", { required: true })}
                    value={" Dudu-Osun Shea Buter With Fragnance"}
                  />
                  <span className="checkbox-checkmark"></span>
                </label>
              </div>
            </FormInputContainerOptions>
            <FormInputContainerOptions>
              <img width={150} height={150} src={img6} alt="" srcset="" />
              <div>
                <label className="checkbox-container">
                  Dudu-Osun Moisturizing Lotion
                  <input
                    type="checkbox"
                    name="prodAwareness"
                    {...register("prodAwareness", { required: true })}
                    value={"Dudu-Osun Moisturizing Lotion"}
                  />
                  <span className="checkbox-checkmark"></span>
                </label>
              </div>
            </FormInputContainerOptions>
          </FormInputContainer>
        </Card>

        <Card>
          <FormInputControl>
            <Label>
              {" "}
              <b style={{ color: "black", fontSize: "12px" }}>
                What is your gender?
              </b>{" "}
            </Label>
            {errors.gender?.type === "required" && (
              <FormValidationError text={"This Field is Required"} />
            )}
          </FormInputControl>
          <FieldSet>
            <FormInputContainer style={{ display: "block" }}>
              <label className="radio-container">
                Male
                <input
                  type="radio"
                  {...register("gender", { required: true })}
                  name="gender"
                  value="Male"
                />
                <span className="radio-checkmark"></span>
              </label>

              <label className="radio-container">
                Female
                <input
                  type="radio"
                  {...register("gender", { required: true })}
                  name="gender"
                  value="Female"
                />
                <span className="radio-checkmark"></span>
              </label>

              <label className="radio-container">
                None of the above
                <input
                  type="radio"
                  {...register("gender", { required: true })}
                  name="gender"
                  value="None of the above"
                />
                <span className="radio-checkmark"></span>
              </label>
            </FormInputContainer>
          </FieldSet>
        </Card>
        <Card>
          <FormInputControl>
            <Label>
              {" "}
              <b style={{ color: "black", fontSize: "12px" }}>
                What is your age?
              </b>{" "}
            </Label>
            {errors.age?.type === "required" && (
              <FormValidationError text={"This Field is Required"} />
            )}
          </FormInputControl>

          <FormInputControl>
            <Controller
              name="age"
              control={control}
              rules={{ required: true }}
              defaultValue={""}
              render={({ field }) => (
                <Select
                  placeholder="Please Select Age Range"
                  options={[
                    {
                      value: "Under 18 years old",
                      label: "Under 18 years old",
                    },
                    { value: "18-24 years old", label: "18-24 years old" },
                    { value: "25-34 years old", label: "25-34 years old" },
                    { value: "35-44 years old", label: "35-44 years old" },
                    { value: "45-54 years old", label: "45-54 years old" },
                    { value: "55-64 years old", label: "55-64 years old" },
                    { value: "65-74 years old", label: "65-74 years old" },
                    { value: "75 years or older", label: "75 years or older" },
                  ]}
                  {...field}
                />
              )}
            />
          </FormInputControl>
        </Card>
        <Card>
          <FormInputControl>
            <Label>
              {" "}
              <b style={{ color: "black", fontSize: "12px" }}>
                How many soaps in the pack you bought?
              </b>{" "}
            </Label>
            {errors.treatmentLocation?.type === "required" && (
              <FormValidationError text={"This Field is Required"} />
            )}
          </FormInputControl>
          <FormInputControl>
            <Controller
              name="treatmentLocation"
              control={control}
              rules={{ required: true }}
              defaultValue={""}
              render={({ field }) => (
                <Select
                  placeholder="Please Select Quantity"
                  options={[
                    { value: "1", label: "1" },
                    { value: "2", label: "2" },
                    {
                      value: "3",
                      label: "3",
                    },
                    {
                      value: "4",
                      label: "4",
                    },
                    { value: "5", label: "5" },
                    { value: "6", label: "6" },
                    { value: "7", label: "7" },
                    { value: "8", label: "8" },
                    { value: "9", label: "9" },
                    { value: "10", label: "10" },
                    { value: "11", label: "11" },
                    { value: "12", label: "12" },
                    { value: "24", label: "24" },
                  ]}
                  {...field}
                />
              )}
            />
          </FormInputControl>
        </Card>

        <FormInputControl style={{ width: "100px" }}>
          <SubmitBtn bgColor={"rgb(68,147,72)"}>Submit</SubmitBtn>
        </FormInputControl>
        <TermsConditions>
          <Link to="https://sproxil.com/terms-of-service/" target="_blank">
            Terms and Conditions
          </Link>
        </TermsConditions>
      </Form>
    </Container>
  );
}

export default TropicalNatural;
