import React, { useContext, useEffect, useState } from "react";
import { format } from "date-fns";
import tropical_header_img from "../../images/headerimges/moms-pride/brand.png";
import brandLogo from "../../images/headerimges/moms-pride/prod.png";
import "./checkbox.css";
import "./radio.css";
import {
  Container,
  Form,
  FormInputContainer,
  FormInputControl,
  FormValidationError,
  Input,
  Label,
  PhoneNumber,
  SubmitBtn,
  TermsConditions,
} from "../../components/components";
//
import RICIBs from "react-individual-character-input-boxes";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import Geocode from "react-geocode";
import { ngSates } from "../../utils";
import { Link, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { useMutation } from "react-query";
import { useMemo } from "react";
import { contextContainer } from "../../Context";
import Hero from "../../components/header/Hero";
import Header from "../../components/header/Header";
import Card from "../../components/card/Card";

function MomsPride() {
  //state variabless
  Geocode.setApiKey(`${process.env.REACT_APP_GOOGLE_API}`);
  Geocode.setLanguage("en");
  // Geocode.setLocationType("ROOFTOP");
  Geocode.enableDebug();
  const [status, setStatus] = useState(false);
  const [old, setOld] = useState(false);
  const { setLoading } = useContext(contextContainer);
  const [searchParams] = useSearchParams();
  const [lgas, setLgas] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [lastPhone3Digits, setLastPhone3Digits] = useState("");
  const [state, setState] = useState("");
  const [lga, setLga] = useState("");
  const [town, setTown] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [isVissible, setIsvissible] = useState(true);
  const [params, setParams] = useState({
    campaignCode: "",
    otp: "",
    submittedByUserId: "",
    channel: "direct",
  });
  const [purchaseDate, setPurchaseDate] = useState("");
  const [prodName, setProdName] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [customerInfo, setCustomerInfo] = useState({
    lat: null,
    lng: null,
    firstName: null,
    lastName: null,
    bussinessName: null,
    address: null,
    market: null,
    state: null,
    lga: null,
    town: null,
    carton_qty: "",
  });
  // const [completeAddressProvidedType, setCompleteAddressProvidedType] =
  //   useState("user_supplied");

  useMemo(
    () =>
      setParams({
        campaignCode: searchParams.get("code"),
        otp: searchParams.get("otp"),
        submittedByUserId: parseInt(searchParams.get("userId")),
        channel: searchParams.get("channel") || "direct",
      }),
    []
  );

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_MALARIA_BASE_URL}/mamapride/check/surveycode?surveyCode=${params.campaignCode}`
      )
      .then((resp) => {
        const data = resp.data.data;
        setPurchaseDate(
          format(new Date(data.createdAt), "yyyy-MM-dd hh:mm:ss")
        );
        setProdName(data.prod_name);
        setPhoneNumber(data.phone_number);
      })
      .catch((err) => {
        if (err.message === "Network Error")
          showAlert("Error", err.message, "error", "red");
        else showAlert("Error", err.response.data.message, "error", "red");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (params.channel === "direct") {
      navigator.permissions.query({ name: "geolocation" }).then((resp) => {
        if (resp.state === "granted") {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(success, error);
          }
        } else if (resp.state === "denied") console.log("Access Denied");
      });
    }
    function success(position) {
      setIsvissible(false);
      setLongitude(position.coords.longitude);
      setLatitude(position.coords.latitude);
      Geocode.fromLatLng(
        position.coords.latitude,
        position.coords.longitude
      ).then(
        (response) => {
          for (
            let i = 0;
            i < response.results[0].address_components.length;
            i++
          ) {
            for (
              let j = 0;
              j < response.results[0].address_components[i].types.length;
              j++
            ) {
              switch (response.results[0].address_components[i].types[j]) {
                case "neighborhood":
                  setTown(response.results[0].address_components[i].long_name);
                  break;
                case "administrative_area_level_2":
                  setLga(response.results[0].address_components[i].long_name);
                  break;
                case "administrative_area_level_1":
                  setState(response.results[0].address_components[i].long_name);
                  break;
                default:
              }
            }
          }
        },
        (error) => {
          setIsvissible(true);
          console.error(error);
        }
      );
    }

    function error(err) {
      console.log(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Alert function
  const showAlert = (title, text, icon, confirmButtonColor) => {
    Swal.fire({
      title: title,
      html: text,
      icon: icon,
      confirmButtonText: "OK",
      confirmButtonColor: confirmButtonColor,
    }).then((response) => {});
  };
  function handSetLatLng(e) {
    Geocode.fromAddress(`${e.value}, ${state} state Nigeria`).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setLatitude(lat);
        setLongitude(lng);
      },
      (error) => {
        console.error(error);
      }
    );
  }

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState,
    formState: { errors },
  } = useForm({ count: "count", date: "date" });
  function loadLga(state) {
    const selectedState = ngSates.filter((selectedState) => {
      return state === selectedState.state.name && selectedState.state;
    });
    setLgas(selectedState[0].state.locals);
  }
  const onsubmit = (data) => {
    const payload = {
      lat: customerInfo?.lat || JSON.stringify(latitude),
      lng: customerInfo?.lng || JSON.stringify(longitude),
      channel: params.channel,
      firstName: data?.firstName,
      lastName: data?.lastName,
      bussinessName: data?.bussinessName,
      address: data?.address,
      market: data?.market,
      state: data?.state?.value || data?.state || state,
      lga: data?.lga.value || data?.lga || lga,
      town: data?.town || town,
      phoneNumber,
      survey_code: params.campaignCode,
      carton_qty: data?.carton_qty.value || data?.carton_qty,
      submitted_by: params.submittedByUserId,
    };
    console.log(payload);
    mutate(payload);
  };

  function handlesetLastPhone3Digits(e) {
    setLastPhone3Digits(e);
  }
  const { isLoading, mutate } = useMutation(
    (variables) => getSubmitSurvey(variables),
    {
      onSuccess(data, variables, context) {
        showAlert("Success", data.data.message, "success", "green");
      },
      onError(error) {
        if (error.code === "ERR_NETWORK") {
          showAlert("Error", error.message, "error", "red");
        } else {
          showAlert("Error", error.response.data.message, "error", "red");
        }
      },
    }
  );

  const sendReturningRetailer = useMutation(
    (variables) => {
      return axios.post(
        `${process.env.REACT_APP_MALARIA_BASE_URL}/mamapride/old-retailer`,
        variables
      );
    },
    {
      onSuccess(data, variables, context) {
        console.log(data.data.data);
        const customer = data.data.data[0];
        setStatus(true);
        setOld(true);
        setIsvissible(true);
        setCustomerInfo({
          firstName: customer.firstName,
          lastName: customer.lastName,
          bussinessName: customer.bussinessName,
          address: customer.address,
          market: customer.market,
          state: customer.state,
          lga: customer.lga,
          town: customer.town,
          lat: customer.lat,
          lng: customer.lng,
          phoneNumber: customer.phoneNumber,
          carton_qty: customer.carton_qty,
        });
      },
      onError(error) {
        if (error.code === "ERR_NETWORK") {
          showAlert("Error", error.message, "error", "red");
        } else if (error.response.status === 608) {
          showAlert("Error", error.response.data.message, "error", "red");
        } else {
          setStatus(true);
          setOld(false);
        }
      },
    }
  );

  const checkCustomer = useForm();
  function checkCustomerData(data) {
    data.survey_code = params.campaignCode;
    sendReturningRetailer.mutate(data);
  }

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  return (
    <Container>
      <Hero bgImg={tropical_header_img} brandLogo={brandLogo} />
      <Card borderTop={"5px solid rgb(221,52,50)"} marginTop={"120px"}>
        <Header
          headerText={`Please answer the questions truthfully to help us be better informed
        about Moms Pride product`}
          headerTilte={"Moms Pride Loyalty Campaign"}
        />
      </Card>
      {!status && (
        <Form onSubmit={checkCustomer.handleSubmit(checkCustomerData)}>
          <Card>
            <FormInputControl>
              <Label>
                {" "}
                <b style={{ color: "black", fontSize: "12px" }}>Phone Number</b>
              </Label>
              {checkCustomer.formState.errors.phoneNumber?.type ===
                "required" && (
                <FormValidationError text={"This Field is Required"} />
              )}
            </FormInputControl>
            <FormInputControl>
              <Input
                placeholder="Please enter your phone number"
                {...checkCustomer.register("phoneNumber", { required: true })}
                bdColor={"rgb(221,52,50)"}
              />
            </FormInputControl>

            <FormInputControl style={{ width: "100px" }}>
              <SubmitBtn bgColor={"rgb(221,52,50)"}>Check</SubmitBtn>
            </FormInputControl>
          </Card>
        </Form>
      )}

      <>
        {status && (
          <Form onSubmit={handleSubmit(onsubmit)}>
            <Card>
              <FormInputContainer>
                <Label>
                  <b style={{ color: "black" }}>Product Name: </b>
                </Label>

                <FormInputControl>{prodName}</FormInputControl>
              </FormInputContainer>
              <FormInputContainer>
                <FormInputControl>
                  <Label>
                    <b style={{ color: "black" }}>Date of purchase: </b>
                  </Label>
                </FormInputControl>
                <FormInputControl>{purchaseDate}</FormInputControl>
              </FormInputContainer>
              <FormInputControl>
                <Label>
                  {" "}
                  <b style={{ color: "black" }}>
                    Please complete your phone no
                  </b>
                </Label>
                <FormInputContainer style={{ marginTop: "0" }}>
                  <PhoneNumber>
                    {phoneNumber.substring(0, phoneNumber.length - 3) ||
                      "XXXXXXXXXXX"}
                  </PhoneNumber>
                  <FormInputControl>
                    <RICIBs
                      amount={3}
                      handleOutputString={(e) => {
                        handlesetLastPhone3Digits(e);
                      }}
                      inputProps={[
                        {
                          style: {
                            width: "25px",
                            height: "25px",
                            fontSize: "16px",
                            border: "2px solid rgb(221,52,50)",
                          },
                        },
                        {
                          style: {
                            width: "25px",
                            height: "25px",
                            fontSize: "14px",
                            border: "2px solid rgb(221,52,50)",
                          },
                        },
                        {
                          style: {
                            width: "25px",
                            height: "25px",
                            fontSize: "14px",
                            border: "2px solid rgb(221,52,50)",
                          },
                        },
                      ]}
                    />
                  </FormInputControl>
                </FormInputContainer>
              </FormInputControl>
            </Card>
            <Card>
              <FormInputControl>
                <Label>
                  {" "}
                  <b style={{ color: "black", fontSize: "12px" }}>First Name</b>
                </Label>
                {errors.FirstName?.type === "required" && (
                  <FormValidationError text={"This Field is Required"} />
                )}
              </FormInputControl>
              <FormInputControl>
                <Input
                  readOnly={old}
                  defaultValue={customerInfo.firstName}
                  {...register("firstName", { required: true })}
                  bdColor={"rgb(221,52,50)"}
                />
              </FormInputControl>
            </Card>

            <Card>
              <FormInputControl>
                <Label>
                  {" "}
                  <b style={{ color: "black", fontSize: "12px" }}>Last Name</b>
                </Label>
                {errors.lastName?.type === "required" && (
                  <FormValidationError text={"This Field is Required"} />
                )}
              </FormInputControl>
              <FormInputControl>
                <Input
                  readOnly={old}
                  defaultValue={customerInfo.lastName}
                  {...register("lastName", { required: true })}
                  bdColor={"rgb(221,52,50)"}
                />
              </FormInputControl>
            </Card>
            <Card>
              <FormInputControl>
                <Label>
                  {" "}
                  <b style={{ color: "black", fontSize: "12px" }}>
                    Bussiness Name
                  </b>
                </Label>
                {errors.address?.type === "required" && (
                  <FormValidationError text={"This Field is Required"} />
                )}
              </FormInputControl>
              <FormInputControl>
                <Input
                  readOnly={old}
                  defaultValue={customerInfo.bussinessName}
                  {...register("bussinessName", { required: true })}
                  bdColor={"rgb(221,52,50)"}
                />
              </FormInputControl>
            </Card>

            <Card>
              <FormInputControl>
                <Label>
                  {" "}
                  <b style={{ color: "black", fontSize: "12px" }}>Address</b>
                </Label>
                {errors.address?.type === "required" && (
                  <FormValidationError text={"This Field is Required"} />
                )}
              </FormInputControl>
              <FormInputControl>
                <Input
                  readOnly={old}
                  defaultValue={customerInfo.address}
                  {...register("address", { required: true })}
                  bdColor={"rgb(221,52,50)"}
                />
              </FormInputControl>
            </Card>

            <Card>
              <FormInputControl>
                <Label>
                  {" "}
                  <b style={{ color: "black", fontSize: "12px" }}>Market</b>
                </Label>
                {errors.market?.type === "required" && (
                  <FormValidationError text={"This Field is Required"} />
                )}
              </FormInputControl>
              <FormInputControl>
                <Input
                  readOnly={old}
                  defaultValue={customerInfo.market}
                  {...register("market", { required: true })}
                  bdColor={"rgb(221,52,50)"}
                />
              </FormInputControl>
            </Card>

            {isVissible && (
              <Card>
                <>
                  <FormInputControl>
                    <Label>
                      {" "}
                      <b style={{ color: "black", fontSize: "12px" }}>
                        Name of state where Bussiness is located
                      </b>
                    </Label>
                    {errors.state?.type === "required" && (
                      <FormValidationError text={"This Field is Required"} />
                    )}
                  </FormInputControl>
                  <FormInputControl>
                    <Controller
                      name="state"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={{
                        value: customerInfo.state,
                        label: customerInfo.state,
                      }}
                      render={({ field }) => (
                        <Select
                          isDisabled={old}
                          options={ngSates.map((state) => {
                            return {
                              label: state.state.name,
                              value: state.state.name,
                            };
                          })}
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            setState(e.value);
                            loadLga(e.value);
                          }}
                        />
                      )}
                    />
                  </FormInputControl>
                </>
              </Card>
            )}
            {isVissible && (
              <Card>
                <>
                  {" "}
                  <FormInputControl>
                    <Label>
                      {" "}
                      <b style={{ color: "black", fontSize: "12px" }}>
                        Name of LGA where Bussiness is located
                      </b>{" "}
                    </Label>
                    {errors.lga?.type === "required" && (
                      <FormValidationError text={"This Field is Required"} />
                    )}
                  </FormInputControl>
                  <FormInputControl>
                    <Controller
                      name="lga"
                      control={control}
                      defaultValue={{
                        value: customerInfo.lga,
                        label: customerInfo.lga,
                      }}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Select
                          isDisabled={old}
                          readOnly={old}
                          placeholder="Select a local government area"
                          options={
                            lgas.length > 0
                              ? lgas.map((lga) => {
                                  return { value: lga.name, label: lga.name };
                                })
                              : []
                          }
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            handSetLatLng(e);
                          }}
                        />
                      )}
                    />
                  </FormInputControl>
                </>
              </Card>
            )}

            {isVissible && (
              <Card>
                <>
                  <FormInputControl>
                    <Label>
                      {" "}
                      <b style={{ color: "black", fontSize: "12px" }}>
                        Name of town where Bussiness is Located
                      </b>
                    </Label>
                    {errors.town?.type === "required" && (
                      <FormValidationError text={"This Field is Required"} />
                    )}
                  </FormInputControl>
                  <FormInputControl>
                    <Input
                      readOnly={old}
                      defaultValue={customerInfo.town}
                      {...register("town", { required: true })}
                      bdColor={"rgb(221,52,50)"}
                    />
                  </FormInputControl>
                </>
              </Card>
            )}
            {/* 
            <Card>
              <FormInputControl>
                <Label>
                  {" "}
                  <b style={{ color: "black", fontSize: "12px" }}>
                    What is your gender?
                  </b>{" "}
                </Label>
                {errors.gender?.type === "required" && (
                  <FormValidationError text={"This Field is Required"} />
                )}
              </FormInputControl>
              <FieldSet bdColor={"rgb(221,52,50)"}>
                <FormInputContainer style={{ display: "block" }}>
                  <label className="radio-container">
                    Male
                    <input
                      type="radio"
                      {...register("gender", { required: true })}
                      name="gender"
                      value="Male"
                    />
                    <span className="radio-checkmark"></span>
                  </label>

                  <label className="radio-container">
                    Female
                    <input
                      type="radio"
                      {...register("gender", { required: true })}
                      name="gender"
                      value="Female"
                    />
                    <span className="radio-checkmark"></span>
                  </label>

                  <label className="radio-container">
                    None of the above
                    <input
                      type="radio"
                      {...register("gender", { required: true })}
                      name="gender"
                      value="None of the above"
                    />
                    <span className="radio-checkmark"></span>
                  </label>
                </FormInputContainer>
              </FieldSet>
            </Card> */}
            {/* <Card>
              <FormInputControl>
                <Label>
                  {" "}
                  <b style={{ color: "black", fontSize: "12px" }}>
                    What is your age?
                  </b>{" "}
                </Label>
                {errors.age?.type === "required" && (
                  <FormValidationError text={"This Field is Required"} />
                )}
              </FormInputControl>

              <FormInputControl>
                <Controller
                  name="age"
                  control={control}
                  rules={{ required: true }}
                  defaultValue={""}
                  render={({ field }) => (
                    <Select
                      placeholder="Please Select Age Range"
                      options={[
                        {
                          value: "Under 18 years old",
                          label: "Under 18 years old",
                        },
                        {
                          value: "18-24 years old",
                          label: "18-24 years old",
                        },
                        {
                          value: "25-34 years old",
                          label: "25-34 years old",
                        },
                        {
                          value: "35-44 years old",
                          label: "35-44 years old",
                        },
                        {
                          value: "45-54 years old",
                          label: "45-54 years old",
                        },
                        {
                          value: "55-64 years old",
                          label: "55-64 years old",
                        },
                        {
                          value: "65-74 years old",
                          label: "65-74 years old",
                        },
                        {
                          value: "75 years or older",
                          label: "75 years or older",
                        },
                      ]}
                      {...field}
                    />
                  )}
                />
              </FormInputControl>
            </Card> */}
            <Card>
              <FormInputControl>
                <Label>
                  {" "}
                  <b style={{ color: "black", fontSize: "12px" }}>
                    How many Carton Do you order in a month?
                  </b>{" "}
                </Label>
                {errors.carton_qty?.type === "required" && (
                  <FormValidationError text={"This Field is Required"} />
                )}
              </FormInputControl>
              <FormInputControl>
                <Controller
                  name="carton_qty"
                  control={control}
                  rules={{ required: true }}
                  defaultValue={{
                    value: customerInfo.carton_qty,
                    label: customerInfo.carton_qty,
                  }}
                  render={({ field }) => (
                    <Select
                      isDisabled={old}
                      placeholder="Please Select Quantity"
                      options={[
                        { value: "Below 10", label: "Below 10" },
                        {
                          value: "Between 10 and 20",
                          label: "Between 10 and 20",
                        },
                        {
                          value: "Between 21 and 30",
                          label: "Between 21 and 30",
                        },
                        {
                          value: "Between 31 and 45",
                          label: "Between 31 and 45",
                        },
                        {
                          value: "Between 46 and 75",
                          label: "Between 46 and 75",
                        },
                        { value: "Above 76", label: "Above 76" },
                      ]}
                      {...field}
                    />
                  )}
                />
              </FormInputControl>
            </Card>

            <FormInputControl style={{ width: "100px" }}>
              <SubmitBtn bgColor={"rgb(221,52,50)"}>Submit</SubmitBtn>
            </FormInputControl>
          </Form>
        )}
      </>
      <TermsConditions>
        <Link to="https://sproxil.com/terms-of-service/" target="_blank">
          Terms and Conditions
        </Link>
      </TermsConditions>
    </Container>
  );
}

async function getSubmitSurvey(data) {
  const response = await axios.post(
    `${process.env.REACT_APP_MALARIA_BASE_URL}/mamapride/surveyform`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response;
}

export default MomsPride;
